import { Google } from 'react-bootstrap-icons'
import { ProjectLink, ProjectLinks, ProjectTitle, Showreel } from '../components'

const GridJumper = (props) => {
  const images = [
    {
      thumbnail: '/images/jumper/snapshot-1-thumb.jpg',
      original: '/images/jumper/snapshot-1-600x375.jpg',
      fullscreen: '/images/jumper/snapshot-1-1280x800.jpg',
    },
    {
      thumbnail: '/images/jumper/snapshot-2-thumb.jpg',
      original: '/images/jumper/snapshot-2-600x375.jpg',
      fullscreen: '/images/jumper/snapshot-2-1280x800.jpg',
    },
    {
      thumbnail: '/images/jumper/snapshot-3-thumb.jpg',
      original: '/images/jumper/snapshot-3-600x375.jpg',
      fullscreen: '/images/jumper/snapshot-3-1280x800.jpg',
    },
    {
      thumbnail: '/images/jumper/snapshot-4-thumb.jpg',
      original: '/images/jumper/snapshot-4-600x375.jpg',
      fullscreen: '/images/jumper/snapshot-4-1280x800.jpg',
    },
    {
      thumbnail: '/images/jumper/snapshot-5-thumb.jpg',
      original: '/images/jumper/snapshot-5-600x375.jpg',
      fullscreen: '/images/jumper/snapshot-5-1280x800.jpg',
    },
    {
      thumbnail: '/images/jumper/snapshot-6-thumb.jpg',
      original: '/images/jumper/snapshot-6-600x375.jpg',
      fullscreen: '/images/jumper/snapshot-6-1280x800.jpg',
    },
  ]

  return (
    <article className={props.swap ? "swap" : undefined}>
      <Showreel images={images} />

      <div className="grid-body">
        <ProjectTitle
          name="Grid Jumper"
          year="2014 - 2015"
          tech="Unity"
          techLink="http://unity3d.com/" />

        <p>
          Grid Jumper is an arcade platformer available for Android devices. Inspired by the famous DOS game
          {' '}
          <a href="http://en.wikipedia.org/wiki/SkyRoads_%28video_game%29" target="_blank" rel="noreferrer">SkyRoads</a>,
          the goal is to reach the end of the level by dodging and jumping past the incoming obstacles. In addition to
          regular levels the game has an endless <i>Challenge</i> mode for competitive play.
        </p>
        <p>
          As they say, the last 10% – polishing, refining, and tweaking – takes 90% of the project's time.
          I spent more hours than I care to remember tuning the graphics, levels, and difficulty. That's one
          reason why there's only six levels; level design was becoming too time consuming and not what I really
          wanted to focus on. That said, I thoroughly enjoyed the project and am happy with the outcome.
        </p>

        <ProjectLinks>
          <ProjectLink
            href="https://play.google.com/store/apps/details?id=com.pekkahellsten.gridjumper"
            targetBlank
            tooltip="View Grid Jumper at Google Play">
            <Google />
            <span>Google Play</span>
          </ProjectLink>
        </ProjectLinks>
      </div>
    </article>
  )
}

export default GridJumper
