import React from 'react'

const ProjectTitle = (props) => {
  return (
    <div className="project-title">
      <h3>{props.name}</h3>
      <footer>
        <div className="project-year">{props.year}</div>
        <div>Developed with <a href={props.techLink} target="_blank" rel="noreferrer">{props.tech}</a></div>
      </footer>
    </div>
  )
}

export default ProjectTitle
