import { Github } from 'react-bootstrap-icons'
import { ProjectCredits, ProjectLink, ProjectLinks, ProjectTitle, Showreel } from '../components'

const TheLoneCabin = (props) => {
  const images = [
    {
      thumbnail: '/images/lonecabin/snapshot-1-thumb.jpg',
      original: '/images/lonecabin/snapshot-1-600x375.jpg',
      fullscreen: '/images/lonecabin/snapshot-1-1280x800.jpg',
    },
    {
      thumbnail: '/images/lonecabin/snapshot-2-thumb.jpg',
      original: '/images/lonecabin/snapshot-2-600x375.jpg',
      fullscreen: '/images/lonecabin/snapshot-2-1280x800.jpg',
    },
    {
      thumbnail: '/images/lonecabin/snapshot-3-thumb.jpg',
      original: '/images/lonecabin/snapshot-3-600x375.jpg',
      fullscreen: '/images/lonecabin/snapshot-3-1280x800.jpg',
    },
    {
      thumbnail: '/images/lonecabin/snapshot-4-thumb.jpg',
      original: '/images/lonecabin/snapshot-4-600x375.jpg',
      fullscreen: '/images/lonecabin/snapshot-4-1280x800.jpg',
    },
    {
      thumbnail: '/images/lonecabin/snapshot-5-thumb.jpg',
      original: '/images/lonecabin/snapshot-5-600x375.jpg',
      fullscreen: '/images/lonecabin/snapshot-5-1280x800.jpg',
    },
  ]

  return (
    <article className={props.swap ? "swap" : undefined}>
      <Showreel
        images={images}
        video={{
          id: 'OKkEWpzjAKY',
          thumbnail: '/images/lonecabin/snapshot-video-thumb.jpg'
        }} />

      <div className="grid-body">
        <ProjectTitle
          name="The Lone Cabin"
          year="2013 - 2014"
          tech="Unity"
          techLink="http://unity3d.com/" />

        <p>
          The Lone Cabin is a <del>triple-A</del> action <del>RPG</del> game where you play as a hiker who comes across...
          a lone cabin in the woods! You soon discover things are not as they seem, and have to fight your way out of the
          dangerous forest.
        </p>
        <p>
          The project started as an introduction to Unity but grew up to be much larger as I fell more and more captivated
          by the game engine and the project itself. Inspired by the movie
          {' '}
          <a href="https://en.wikipedia.org/wiki/Dog_Soldiers_(film)" target="_blank" rel="noreferrer">Dog Soldiers</a>,
          the game concept was to survive a night in the set piece cabin by using furniture and surroundings to fortify
          yourself against a werewolf attack.
        </p>
        <p>
          During development it became obvious that my ambitions were too high so I scrapped the survival mechanics.
          However, the original theme stayed intact. The cabin, toolshed, and shotgun were custom made with
          {' '}
          <a href="http://www.blender.org/" target="_blank" rel="noreferrer">Blender</a>, but the remaining models, sound effects, and music were acquired
          from the <a href="https://assetstore.unity.com/" target="_blank" rel="noreferrer">Asset Store</a> and
          {' '}
          <a href="https://freesound.org/" target="_blank" rel="noreferrer">Freesound</a>.
        </p>

        <ProjectCredits
          person="Eino Hellsten"
          domain="3D Modeling"
          note="breathtaking toolshed and shotgun" />

        <ProjectLinks>
          <ProjectLink
            href="https://github.com/pekkahe/the-lone-cabin-samples"
            targetBlank
            tooltip="View source samples at GitHub">
            <Github />
            <span>GitHub</span>
          </ProjectLink>
        </ProjectLinks>
      </div>
    </article>
  )
}

export default TheLoneCabin
