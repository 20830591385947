import React from 'react'

const ProjectCredits = (props) => {
  return (
    <table className="project-credits">
      <tbody>
        <tr>
          <th>Additional Credits</th>
          <td>{props.person}, {props.domain} – <i>{props.note}</i></td>
        </tr>
      </tbody>
    </table>
  )
}

export default ProjectCredits
